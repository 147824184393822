import { Component } from "react";
import {
    Row,
    Col,
    Label,
    Button
} from "reactstrap";
import {
    PictureAsPdf,
    GetApp,
} from '@material-ui/icons';
import pdf from "../resouse/file/1-AVISO_DE_PRIVACIDAD_INTEGRAL_PARA_CLIENTES.pdf";

class AvisoPrivacidad extends Component {

    constructor(props) {
        super(props);
        this.state = {
            styleTitulo_1: {
                fontWeight: "bold",
                fontSize: "24px",
                fontFamily: "arial",
            },
            styleTitulo_2: {
                fontWeight: "bold",
                fontSize: "22px",
                fontFamily: "arial"
            },
            styleText: {
                fontSize: "18px",
                fontFamily: "arial"
            }
        };
    }


    componentDidMount() {
        // eslint-disable-next-line no-restricted-globals
        // let screenHeight = screen.height.toString();
        // eslint-disable-next-line no-restricted-globals
        // let screenWidth = screen.width.toString();        
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);



        this.setState({
            isMobileDevice,
        });

    }

    render() {
        return (
            <>
                <div
                    style={{
                        // backgroundColor: "#005E8A",
                        backgroundImage: "#FF",
                        borderRadius: "8px",
                        borderColor: "red",
                        marginTop: "5px",
                        marginLeft: "10%",
                        marginRight: "10%"
                    }}
                >
                    <div
                        style={{
                            color: "#000"
                        }}
                    >
                        <br /><br />
                        {this.state.isMobileDevice ?
                            <Row>
                                <Col
                                    md="4"
                                >
                                    <a
                                        style={{
                                            textDecoration: "none",
                                            cursor: "pointer",
                                        }}
                                        href={pdf}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button
                                            style={{
                                                width: "100%",
                                                marginRight: "15px",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                                color: "#F7F7F7",
                                                backgroundColor: "#AD0B00",
                                                borderColor: "#540600"
                                            }}
                                        >

                                            <PictureAsPdf
                                                style={{
                                                    marginRight: "10px",

                                                }}
                                            />

                                            Ver en PDF

                                        </Button>
                                    </a>
                                </Col>
                                <Col
                                    md="4"
                                    style={{
                                        marginTop:"10px"
                                    }}
                                >
                                    <a
                                        style={{
                                            textDecoration: "none",
                                            cursor: "pointer",
                                        }}
                                        href={pdf}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download="1-AVISO_DE_PRIVACIDAD_INTEGRAL_PARA_CLIENTES.pdf" >
                                        <Button
                                            style={{
                                                width: "100%",
                                                color: "#F7F7F7",
                                                backgroundColor: "#009EF2",
                                                borderColor: "#006399"
                                            }}
                                        >
                                            <GetApp
                                                style={{
                                                    marginRight: "10px",

                                                }}
                                            />
                                            Descargar en PDF
                                        </Button>
                                    </a>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "right"
                                    }}
                                >
                                    <a
                                        style={{
                                            textDecoration: "none",
                                            cursor: "pointer",
                                        }}
                                        href={pdf}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button
                                            style={{
                                                width: "190px",
                                                marginRight: "15px",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                                color: "#F7F7F7",
                                                backgroundColor: "#AD0B00",
                                                borderColor: "#540600"
                                            }}
                                        >

                                            <PictureAsPdf
                                                style={{
                                                    marginRight: "10px",

                                                }}
                                            />

                                            Ver en PDF

                                        </Button>
                                    </a>
                                    <a
                                        style={{
                                            textDecoration: "none",
                                            cursor: "pointer",
                                        }}
                                        href={pdf}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download="1-AVISO_DE_PRIVACIDAD_INTEGRAL_PARA_CLIENTES.pdf" >
                                        <Button
                                            style={{
                                                width: "190px",
                                                color: "#F7F7F7",
                                                backgroundColor: "#009EF2",
                                                borderColor: "#006399"
                                            }}
                                        >
                                            <GetApp
                                                style={{
                                                    marginRight: "10px",

                                                }}
                                            />
                                            Descargar en PDF
                                        </Button>
                                    </a>
                                </Col>

                            </Row>
                        }

                        <Row
                            style={{
                                marginTop: "30px"
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center",

                                }}
                            >
                                <Label
                                    style={this.state.styleTitulo_1}
                                >
                                    AVISO DE PRIVACIDAD INTEGRAL PARA CLIENTES
                                </Label>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%"
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label
                                    style={this.state.styleTitulo_2}
                                >
                                    I. Identidad y domicilio del responsable.
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",

                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <p
                                    style={this.state.styleText}
                                >
                                    De conformidad con lo establecido en el Artículo 16 último párrafo, artículo 17, fracción II de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y los lineamientos publicados el 17 de enero de 2013, le informamos que Grupo Inmobiliario Rovic S. A. de C.V., (el “Responsable”), con domicilio en 22 Poniente Sur 621 2do piso Int. 2, Colonia Penipak C.P. 29060 de la ciudad de Tuxtla Gutiérrez, Chiapas tratará los  siguientes datos personales y datos personales sensibles (en el caso que se requiera) que recabe de Usted en los términos del presente aviso de privacidad.
                                </p>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: "30px",
                                marginLeft: "2%",
                                marginRight: "2%",

                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label
                                    style={this.state.styleTitulo_2}
                                >
                                    II. Datos personales que serán sometidos a tratamiento:
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",

                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <p
                                    style={this.state.styleText}
                                >
                                    <Row
                                        style={{
                                            marginLeft: "2%"
                                        }}
                                    >
                                        <Col>
                                            •	Nombre
                                            <br />
                                            •	Domicilio
                                            <br />
                                            •	Nacionalidad
                                            <br />
                                            •	Números de teléfonos (de casa, celular y trabajo)
                                            <br />
                                            •	Correo electrónico
                                            <br />
                                            •	Clave del RFC
                                            <br />
                                            •	Firma
                                            <br />
                                            •	Imágenes mediante videos y/o fotografía.
                                        </Col>
                                    </Row>


                                </p>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: "30px",
                                marginLeft: "2%",
                                marginRight: "2%"
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label
                                    style={this.state.styleTitulo_2}
                                >
                                    III. Datos personales sensibles, financieros y/o patrimoniales a recabar:
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <p
                                    style={this.state.styleText}
                                >
                                    <Row
                                        style={{
                                            marginLeft: "2%"
                                        }}
                                    >
                                        <Col>
                                            •	Estado civil
                                            <br />
                                            •	Beneficiarios
                                            <br />
                                            •	Comprobante de ingresos y/o declaración anual
                                            <br />
                                            •	Actividad económica
                                            <br />
                                            •	Tipo de domicilio (vivienda propia, rentada, otro)
                                            <br />
                                            •	Referencias personales y familiares
                                            <br />
                                            •	Aval o coobligado
                                            <br />
                                            •	Fecha de nacimiento
                                            <br />
                                            •	Fecha de boda
                                            <br />
                                            •	Datos de identidad de su cónyuge
                                            <br />
                                            •	Escolaridad

                                        </Col>
                                    </Row>


                                </p>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",

                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <p
                                    style={this.state.styleText}
                                >
                                    Doy mi consentimiento para el tratamiento de mis datos personales sensibles y personales financieros o patrimoniales para las finalidades necesarias para la relación jurídica con el Responsable. (Negarse al tratamiento de sus datos personales sensibles, en los casos en que la relación jurídica lo requiera, tendrá como consecuencia la imposibilidad de establecer dicha relación)


                                </p>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <p
                                    style={this.state.styleText}
                                >

                                    ______________________
                                    <br />
                                    NOMBRE Y FIRMA
                                    <br />
                                    DE CONSENTIMIENTO



                                </p>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%"
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label
                                    style={this.state.styleTitulo_2}
                                >
                                    IV. Finalidades del Tratamiento.
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <p
                                    style={this.state.styleText}
                                >

                                    El tratamiento se hará con las siguientes
                                    <br /> <br />
                                    1.- Finalidades primarias:
                                    <br /> <br />
                                    a. Necesarias para la relación jurídica con el Responsable:
                                    <br /> <br />
                                    <Row
                                        style={{
                                            marginLeft: "2%"
                                        }}
                                    >
                                        <Col>
                                            •	Para mostrar los inmuebles que desea arrendar o comprar. <br />
                                            •	Brindar información sobre los inmuebles que se encuentran en arrendamiento o en venta. <br />
                                            •	Verificar si tiene la capacidad para arrendar o comprar el inmueble. <br />
                                            •	Realizar una investigación sobre su perfil laboral y económico <br />
                                            •	identificar y verificar la información de su obligado solidario y/o aval a efecto de que pueda cumplir con las obligaciones que le corresponderían derivado de las obligaciones contraídas, ya sea por arrendamiento o compraventa de inmueble. <br />
                                            •	En su caso suscripción de los contratos de arrendamiento o compra venta. <br />
                                            •	Para generar una base de datos de los clientes;
                                            <br /> <br />
                                        </Col>
                                    </Row>
                                    b. Otras:
                                    <br /> <br />
                                    <Row
                                        style={{
                                            marginLeft: "2%"
                                        }}
                                    >
                                        <Col>

                                            •	Gestiones de cobro de renta. <br />
                                            •	Atender reclamación o queja en relación con el arrendamiento de inmueble. <br />
                                            •	Gestiones sobre el mantenimiento de inmuebles arrendados y cobro de cuotas. <br />
                                            •	Cumplir con disposiciones aplicables en materia de Prevención de Lavado de Dinero <br />
                                            <br />

                                        </Col>
                                    </Row>
                                    2.- Finalidades secundarias:
                                    <Row
                                        style={{
                                            marginLeft: "2%",

                                        }}
                                    >
                                        <Col>

                                            •	Ofrecer publicidad de nuestros inmuebles que se arriendan.  <br />
                                            •	Seguimiento de satisfacción  <br />
                                            •	Comunicación en las que se puedan asociar imágenes fotográficas o digitales a través de nuestra página web o nuestras redes sociales, para crear una galería en nuestra página de Internet, con fines publicitarios.  <br />


                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: "30px"
                                        }}
                                    >
                                        <Col
                                            style={{
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Usted puede manifestar su negativa previo al tratamiento de sus datos personales, con relación a las finalidades no necesarias, para lo cual, deberá indicar al momento de solicitarle sus datos, los que no desea proporcionar.
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: "30px"
                                        }}
                                    >
                                        <Col
                                            style={{
                                                // fontWeight: "bold"
                                            }}
                                        >
                                            En caso de que no manifieste expresamente su inconformidad, se recabarán todos los datos que consideramos necesarios, de acuerdo a lo establecido en el presente de aviso de privacidad.
                                            No obstante, Usted podrá posteriormente, ejercer sus derechos, como se indica en el siguiente punto.

                                        </Col>
                                    </Row>
                                </p>

                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px"
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label
                                    style={this.state.styleTitulo_2}
                                >
                                    V. Mecanismos para manifestar su negativa para el tratamiento <br /> de datos personales para finalidades no necesarias.
                                </Label>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <Label
                                    style={this.state.styleText}
                                >
                                    Usted puede manifestar su negativa para el tratamiento de sus datos personales para aquellas finalidades que no son necesarias para la relación jurídica de Usted con el Responsable, en las instalaciones físicas de Grupo Inmobiliario Rovic S.A. de C.V., solicitando una entrevista con el Oficial de Privacidad.
                                    También puede hacerlo al siguiente correo electrónico: <Label style={{ fontWeight: "bold" }}> rovicgpm@gmail.com </Label> dirigido a la encargada: C.P. Roberto Borrayes Díaz.

                                </Label>
                            </Col>
                        </Row>


                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label
                                    style={this.state.styleTitulo_2}
                                >
                                    VI. Transferencias de sus datos personales.
                                </Label>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <Label
                                    style={this.state.styleText}
                                >

                                    Le informamos que sus datos personales podrán ser transferidos, con nuestros socios comerciales para el envío de información relacionada con sus servicios o productos, o encuestas de satisfacción, de conformidad con el artículo 36 de la LFPDPPP es necesario contar con su consentimiento expreso para llevar a cabo estas transferencias.

                                </Label>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label
                                    style={this.state.styleTitulo_2}
                                >
                                    VII. Cláusula de consentimiento para transferencia.
                                </Label>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <Label
                                    style={this.state.styleText}
                                >

                                    Doy mi consentimiento para la transferencia de mis datos personales en términos del presente aviso de privacidad. 	Si <input type="checkbox" disabled></input>	No <input type="checkbox" disabled></input>

                                </Label>
                            </Col>
                        </Row>


                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label
                                    style={this.state.styleTitulo_2}
                                >
                                    VIII. Medios para ejercer los derechos ARCO
                                </Label>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <Label
                                    style={this.state.styleText}
                                >

                                    Usted tiene el derecho de: (i) acceder a sus datos personales en nuestro poder y conocer los detalles del tratamiento de los mismos, (ii) rectificarlos en caso de ser inexactos o incompletos, (iii) cancelarlos cuando considere que no se requieren para alguna de las finalidades señaladas en el presente aviso de privacidad, estén siendo utilizados para finalidades no consentidas o haya finalizado la relación contractual o de servicio, o (iv) oponerse al tratamiento de los mismos para fines específicos, según lo señala la ley, (conjuntamente, los “Derechos ARCO”).
                                    Para el ejercicio de sus Derechos ARCO, deberá presentar una solicitud (la “Solicitud ARCO”), al Responsable, a la atención del Oficial de Privacidad, C.P. Roberto Borrayes Díaz al correo electrónico rborrayes@dominosgda.com, acompañada de la siguiente información y documentación:


                                </Label>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <Label
                                    style={this.state.styleText}
                                >
                                    a. Su nombre, domicilio y correo electrónico para poder comunicarle la respuesta a la Solicitud ARCO; <br /><br />

                                    b. Una copia de los documentos que acrediten su identidad (copia de IFE, pasaporte o cualquier otra identificación oficial) o en su caso, los documentos que acrediten su representación legal, cuyo original deberá presentar para poder recibir la respuesta del Responsable;<br /><br />

                                    c. Una descripción clara y precisa de los datos personales respecto de los cuales busca ejercer alguno de los Derechos ARCO;<br /><br />

                                    d. Cualquier documento o información que facilite la localización de sus datos personales, y;<br /><br />

                                    e. En caso de solicitar una rectificación de sus datos personales, deberá indicar también, las modificaciones a realizarse y aportar la documentación que sustente su petición.<br /><br />

                                    El Oficial de Privacidad, C.P. Roberto Borrayes Diaz, responderá su Solicitud ARCO y los motivos de su decisión mediante un correo electrónico en un plazo máximo de 20 días hábiles contados desde el día en que se haya recibido su Solicitud ARCO. En caso de que la Solicitud ARCO se conteste de manera afirmativa o procedente, los cambios solicitados se harán en un plazo máximo de 15 días hábiles. El Responsable podrá notificarle dentro de los plazos referidos en este párrafo la prórroga de los mismos, por una sola vez, por un periodo igual al original.
                                    El Responsable podrá negar el acceso (la “Negativa”) para que usted ejerza sus Derechos ARCO, en los supuestos que lo permita la ley, por lo que deberá informar a usted el motivo de tal decisión.
                                    La Negativa podrá ser parcial, en cuyo caso el Responsable efectuará el acceso, rectificación, cancelación u oposición en la parte procedente.<br /><br />
                                    El ejercicio de los Derechos ARCO será gratuito, pero si usted reitera su solicitud en un periodo menor a doce meses, los costos serán de tres días de Salario Mínimo General Vigente en el Distrito Federal, más I.V.A., a menos que existan modificaciones sustanciales al Aviso de Privacidad, que motiven nuevas Solicitudes ARCO. Usted deberá cubrir los gastos justificados de envío o el costo de reproducción en copias u otros formatos y, en su caso, el costo de la certificación de documentos.
                                    <Label style={{ fontWeight: "bold", marginLeft: "5px" }}>La modalidad en la que se le entregará en su caso, información solicitada, será mediante copias simples o documentos electrónicos.</Label>


                                </Label>
                            </Col>
                        </Row>



                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label
                                    style={this.state.styleTitulo_2}
                                >
                                    IX. Mecanismos y procedimientos para revocar el consentimiento para el <br /> tratamiento de datos personales.
                                </Label>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <Label
                                    style={this.state.styleText}
                                >

                                    Usted podrá revocar su consentimiento para el tratamiento de sus datos personales de la misma forma por la cual otorgo su consentimiento, dirigiéndose al Oficial de Privacidad a que se refiere el punto anterior. Si con posterioridad a la revocación usted solicita la confirmación de la misma, el Responsable le responderá de forma expresa, de la misma forma en que Usted lo solicitó.
                                    Los mecanismos y requisitos serán los mismos, en su caso, que los previstos en el punto VIII.


                                </Label>
                            </Col>
                        </Row>


                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label
                                    style={this.state.styleTitulo_2}
                                >
                                    X. Opciones y medios ofrecidos para limitar el uso o divulgación de sus datos <br /> personales.
                                </Label>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <Label
                                    style={this.state.styleText}
                                >
                                    El Responsable ha designado a un encargado de datos personales (el “Oficial de Privacidad”), <Label style={{ fontWeight: "bold" }} >C.P. Roberto Borrayes Diaz</Label> por lo tanto usted podrá limitar el uso o divulgación de sus datos personales de manera personal ante el Responsable, con los mismos procedimientos establecidos en el apartado VIII.


                                </Label>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label
                                    style={this.state.styleTitulo_2}
                                >
                                    XI. Cambios al aviso de privacidad
                                </Label>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: "2%",
                                marginRight: "2%",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "justify"
                                }}
                            >
                                <Label
                                    style={this.state.styleText}
                                >


                                    Cualquier cambio al aviso de privacidad se publicará por escrito en las instalaciones de Grupo Inmobiliario Rovic S. A. de C.V., así como en la página de internet en el apartado de “www._____________________.com.mx”, así como los procedimientos que dichos cambios generen para el uso, protección y manejo de los datos personales y datos personales sensibles.

                                </Label>
                            </Col>
                        </Row>
                        <br /><br /><br />

                    </div>
                </div>


            </>
        );
    }

}

export default AvisoPrivacidad;