import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AvisoPrivacidad from "./view/AvisoPrivacidad";

function App() {
  return (
    <>
      <Router>
        <Routes>          

          {/* <Route exact path="/" element={<Login/>} />   */}
          <Route exact path="/" element={<AvisoPrivacidad/>} />   
        
        </Routes>
      </Router>
    </>
  );
}

export default App;
